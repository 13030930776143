import { Button, Col, Modal, Row, Typography } from "antd";
import TImage from "components/Common/TImage";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useOrg } from "services/ApiHooks";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import styles from "./OrgDiscountPopup.module.scss";

const { Paragraph } = Typography;

const OrgDiscountPopup = () => {
  const { org: config } = useOrg();
  const listing_config = config?.listing_config;
  const [discountPopupModal, setDiscountPopupModal] = useState<boolean>(
    listing_config?.org_coupon
  );
  const onSubmit = async () => {
    Analytics.track("Org Popup Clicked", {
      cta: "explore",
      type: "discount_coupon",
    });
    setDiscountPopupModal(false);
  };

  useEffect(() => {
    Analytics.track("Org Popup Viewed", {
      type: "discount_coupon",
    });
  }, [config]);

  return (
    <Modal
      open={discountPopupModal}
      onCancel={() => {
        setDiscountPopupModal(false);
      }}
      centered
      width={260}
      styles={{
        body: {
          padding: "4px",
        },
        footer: {
          padding: "4px 20px 24px",
        },
        content: {
          padding: 0,
          overflow: "hidden",
          width: "260px",
        },
      }}
      keyboard={false}
      maskClosable={false}
      closeIcon={<IoClose fontSize={24} color="#1f1f1f" />}
      footer={null}
      className={styles.DiscountModal}
    >
      <Row>
        <TImage
          src="/images/common/discount_poster.svg"
          alt={""}
          width={100}
          className={styles.PreviewImage}
        />
        <Col span={24} className={styles.content}>
          <Paragraph className={styles.Title}>
            {listing_config?.org_coupon?.title || "A little surprise"}
          </Paragraph>
          <Paragraph className={styles.Desc}>
            {listing_config?.org_coupon?.desc ||
              "Extra OFF for you. Will be applied on checkout."}
          </Paragraph>
          <Button className={styles.SubmitBtn} block onClick={onSubmit}>
            Explore
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default OrgDiscountPopup;
