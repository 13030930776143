import { Col, Row, Typography } from "antd";
import Container from "components/Common/Container";
import parse from "html-react-parser";
import { useOrg } from "services/ApiHooks";

const { Paragraph } = Typography;

const FeaturedFooter: React.FC = () => {
  const { org: orgData } = useOrg();
  const footerData = orgData?.listing_config?.sections?.footer;

  return (
    <Container
      type="fluid"
      className="featured-footer"
      style={{ backgroundColor: footerData?.background_color || "#fff" }}
    >
      <Row className="details-container" align="middle" justify="center">
        <Col
          md={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ textAlign: "center" }}
        >
          <Paragraph className="header-title" style={footerData?.title_styles}>
            {footerData?.title ? parse(footerData?.title) : ""}
          </Paragraph>
          <Paragraph
            className="header-description"
            style={footerData?.subtitle_styles}
          >
            {footerData?.subtitle ? parse(footerData?.subtitle) : ""}
          </Paragraph>
          <Row
            align="middle"
            justify="center"
            className="socials-container"
            gutter={40}
          >
            {footerData?.support_links?.length > 0 &&
              footerData?.support_links?.map((item, idx) => (
                <Col className="support-links" key={item?.type + idx}>
                  <Paragraph
                    className="support"
                    onClick={() =>
                      window.open(
                        item?.type === "Email"
                          ? `mailto:${item?.link || "support@topmate.io"}`
                          : item?.link,
                        "_blank"
                      )
                    }
                    style={footerData?.support_styles}
                  >
                    {item?.title || "Support"}
                  </Paragraph>
                </Col>
              ))}
          </Row>
        </Col>
        <Paragraph className="copyright" style={footerData?.copyright_styles}>
          {footerData?.copyright ? parse(footerData?.copyright) : ""}
        </Paragraph>
      </Row>
    </Container>
  );
};

export default FeaturedFooter;
