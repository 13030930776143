import Head from "next/head";

const FeaturedPageSEO = ({ seoData }) => {
  const { title } = seoData;
  console.log("SEO Data: ", seoData);
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={seoData?.description} />
      <meta property="og:title" content={seoData?.ogTitle || seoData?.title} />
      <meta
        property="og:description"
        content={seoData?.ogDescription || seoData?.description}
      />
      <meta
        name="twitter:image:alt"
        content={seoData?.ogImageAlt || seoData?.title}
      />
      <meta
        name="og:image:alt"
        content={seoData?.ogImageAlt || seoData?.title}
      />
      <meta property="og:url" content={seoData?.ogUrl} />
      <meta property="og:type" content={seoData?.ogType || "website"} />
    </Head>
  );
};

export default FeaturedPageSEO;
