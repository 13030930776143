// import Image from "next/image";
import TImage from "components/Common/TImage";
import Link from "next/link";
import { CSSProperties } from "react";
import { useOrg } from "services/ApiHooks";

interface ILogo {
  imageStyle?: CSSProperties;
  width?: number | `${number}`;
  height?: number;
  link?: string;
  logo?: boolean;
  className?: string;
}

const FeaturedLogo: React.FC<ILogo> = ({
  width = 160,
  height = 30,
  imageStyle = {},
  link,
  logo = false,
  className,
  ...rest
}) => {
  const { org: orgData, isLoading: loading } = useOrg();
  return (
    <Link
      href={link || orgData?.listing_config?.logo_redirect || "/"}
      className="logo"
      {...rest}
    >
      <TImage
        src={orgData?.logo}
        styles={imageStyle}
        alt="Logo"
        width={width}
        height={height}
        className={className}
      />
    </Link>
  );
};

export default FeaturedLogo;
