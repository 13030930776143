import { Button, Col, Row, Typography } from "antd";
import Container from "components/Common/Container";
import FeaturedLogo from "components/Common/Whitelabel/Logo";
import Link from "next/link";
import { useOrg } from "services/ApiHooks";

const { Paragraph } = Typography;

const FeaturedTopNav: React.FC = () => {
  const { org: orgData, isLoading: loading } = useOrg();
  const navData = orgData?.listing_config?.sections?.nav;
  return (
    <Container
      type="fluid"
      className="featured-top-nav"
      style={{
        backgroundColor: navData?.background_color || "#fff",
      }}
    >
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
        <Row justify="space-between" align="middle">
          <FeaturedLogo imageStyle={navData?.logo_styles} />
          {navData?.header_button?.visible && (
            <Link target="_blank" href={navData?.header_button?.url}>
              <Button
                className="b2b-signup-btn"
                style={navData?.header_button?.button_styles}
              >
                ✨ {navData?.header_button?.text}
              </Button>
            </Link>
          )}
        </Row>
      </Col>
    </Container>
  );
};

export default FeaturedTopNav;
