import { Col, Row, Typography } from "antd";
import Container from "components/Common/Container";
import parse from "html-react-parser";
import { useOrg } from "services/ApiHooks";

const { Paragraph } = Typography;

const FeaturedHeroSection = () => {
  const { org: orgData, isLoading: loading } = useOrg();
  const heroData = orgData?.listing_config?.sections?.hero;
  return (
    <Container
      type="fluid"
      className="container_header"
      style={{ backgroundColor: heroData?.backgroundColor || "#fff" }}
    >
      <Row className="header-wrapper" align="middle" justify="center">
        <Col
          md={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ textAlign: "center", padding: "0px 12px" }}
        >
          <Paragraph className="header-title" style={heroData?.title_styles}>
            {parse(heroData?.title)}
          </Paragraph>
          <Paragraph
            className="header-description"
            style={heroData?.subtitle_styles}
          >
            {parse(heroData?.subtitle)}
          </Paragraph>
        </Col>
      </Row>
    </Container>
  );
};

export default FeaturedHeroSection;
